import React from 'react';
import { withTranslation } from 'react-i18next';

const HeaderComponent = ({ t }) => (
	<>
		<div className="header-component__top-page"></div>
		<div className="header-component">
			<div className="header-component__img-wrapper">
				<img alt="People First Bank" src="/people-first-bank-left.svg" />
				<img alt="People First Bank Text" src="/people-first-bank-right.svg" />
				<div className="header-component__divider"></div>
				<img alt="Heritage Bank" src="/heritage-bank.svg" />
			</div>
			<div className="header-component__text-wrapper">
				<div className="header-component__top-title">{t('label.to_share_your_people_first_bank')}</div>
				<div className="header-component__bottom-title">{t('label.please_fill_in_your_member_number')}</div>
			</div>
		</div>
		<div className="header-component__bar-component"></div>
	</>
);

export default withTranslation()(HeaderComponent);
