import React from 'react';

const ColorfulHeaderComponent = ({ onBack }) => (
	<>
		<div className="colorful-header-component">
			<img src="/background-logo.png" alt="background logo" />
			<button type="button" aria-label="back" id="back-button" className="colorful-header-component__button-wrapper" onClick={onBack}>
				<span className="icon-chevron-back"></span>
			</button>
		</div>
	</>
);

export default ColorfulHeaderComponent;
