import React from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
	AccountsWithNoEnabledNominatedComponent,
	DividerComponent,
	LoaderComponent,
	SettingsExpandableItemComponent,
	DescriptionComponent
} from '../../components';
import { globalConstants } from '../../constants';
import { selectMemberId } from '../../store/slices/member/selectors';
import { selectSelectedProfile } from '../../store/slices/profile/selectors';
import { selectAccountsWithNoRepresentatives, selectRepresentatives, selectRepresentativesLoading } from '../../store/slices/settings/selectors';
import { showRepresentativesUsersAccount } from '../../store/slices/settings/slice';
import { representativesAssignNomination } from '../../store/slices/settings/thunks';
import { useHookIsHBLBrand } from '../../helpers';

const SettingsRepresentativePage = ({ t }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const memberId = useSelector(selectMemberId);
	const selectedProfile = useSelector(selectSelectedProfile);
	const representativesLoading = useSelector(selectRepresentativesLoading);
	const representatives = useSelector(selectRepresentatives);
	const accountsWithNoRepresentatives = useSelector(selectAccountsWithNoRepresentatives);
	const isHBLbrand = useHookIsHBLBrand();

	const handleDisableClick = (account, user) =>
		dispatch(
			representativesAssignNomination({
				memberId,
				profileId: selectedProfile.profileId,
				accountId: account.accountId,
				representativeId: user.memberId,
				status: globalConstants.DISABLE,
				businessNominationId: account.businessNominationId,
				userMemberId: user.memberId
			})
		).then((status) => !status?.error && navigate('/settings/nominated-representative/disable'));

	const handleEnableClick = (account, user) =>
		dispatch(
			representativesAssignNomination({
				memberId,
				profileId: selectedProfile.profileId,
				accountId: account.accountId,
				representativeId: user.memberId,
				status: globalConstants.ENABLE,
				userMemberId: user.memberId
			})
		).then((status) => !status?.error && navigate('/settings/nominated-representative/enable'));

	const handleBack = () => navigate('/settings');

	return (
		<>
			<DescriptionComponent title={t('label.nominated_representative')} content={t('label.manage_which_other_members')} onBack={handleBack} />
			<div className="settings-page">
				<div className="settings-page__inner">
					{representativesLoading ? (
						<LoaderComponent />
					) : isHBLbrand ? (
						<>
							<label className="settings-page__settings-text">{t('label.nominated_representative_s')}</label>
							<label className="settings-page__settings-header">{t('label.is_a_person_who_has_privileges')}</label>
							<div className="settings-page__nominated-content">
								<span>{t('label.to_add_or_remove')} </span>
								<a href={globalConstants.NOMINATED_REPRESENTATIVE_PDF} target="_blank" rel="noreferrer">
									{t('label.nominated_representative_form')}
								</a>
								<span> {t('label.to')} </span>
								<a href={`mailto:${globalConstants.OPEN_BANKKING_HERITAGE_EMAIL}`}>{globalConstants.OPEN_BANKKING_HERITAGE_EMAIL}</a>
								<span> {t('label.or_your_local_branch')}</span>
							</div>
						</>
					) : (
						<>
							<SettingsExpandableItemComponent
								text={t('label.business_representative_s')}
								header={t('label.is_a_member_who_has_authority')}
								users={representatives?.filter((r) => r.type === globalConstants.ADMIN)}
								onClick={(user) =>
									dispatch(showRepresentativesUsersAccount({ memberId: user.memberId, memberType: user.type, open: !user.open }))
								}
								handleDisableClick={handleDisableClick}
								handleEnableClick={handleEnableClick}
								noDivider={true}
							/>
							<DividerComponent withSpace={true} />
							<SettingsExpandableItemComponent
								text={t('label.nominated_representative_s')}
								header={t('label.is_a_member_who_has_privileges')}
								users={representatives?.filter((r) => r.type === globalConstants.STANDARD)}
								onClick={(user) =>
									dispatch(showRepresentativesUsersAccount({ memberId: user.memberId, memberType: user.type, open: !user.open }))
								}
								handleDisableClick={handleDisableClick}
								handleEnableClick={handleEnableClick}
								noDivider={true}
							/>
							{accountsWithNoRepresentatives?.length > 0 && (
								<>
									<DividerComponent withSpace={true} />
									<AccountsWithNoEnabledNominatedComponent accounts={accountsWithNoRepresentatives} />
								</>
							)}
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default withTranslation()(SettingsRepresentativePage);
