import React from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DescriptionComponent, DividerComponent, HeaderComponent, LinkItemComponent } from '../../components';
import { globalConstants } from '../../constants';
import { selectSelectedProfile, selectSkipProfileScreen } from '../../store/slices/profile/selectors';
import { selectProfile } from '../../store/slices/profile/slice';
import { selectAccountsWithNoRepresentatives, selectRepresentatives } from '../../store/slices/settings/selectors';
import { useHookIsHBLBrand } from '../../helpers';

const SettingsContentPage = ({ t }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const selectedProfile = useSelector(selectSelectedProfile);
	const skipProfileScreen = useSelector(selectSkipProfileScreen);
	const representatives = useSelector(selectRepresentatives);
	const accountsWithNoRepresentatives = useSelector(selectAccountsWithNoRepresentatives);
	const isHBLbrand = useHookIsHBLBrand();

	const handleOnBack = () => {
		dispatch(selectProfile());
		navigate('/profile-selection');
	};

	return (
		<>
			<DescriptionComponent title={t('label.settings')} onBack={!skipProfileScreen && handleOnBack} />
			<div className="settings-page">
				<div className="settings-page__inner">
					<label className="settings-page__settings-text">{t('label.settings')}</label>
					<DividerComponent />
					{selectedProfile?.profileType === globalConstants.PERSONAL ? (
						<>
							<LinkItemComponent
								id="change-notification-settings"
								route="/settings/notification-settings"
								icon="icon-notif"
								label={t('label.change_notification_settings')}
							/>
							<DividerComponent />
							<LinkItemComponent
								id="secondary-user-permission"
								route="/settings/secondary-user-permission"
								icon="icon-settings"
								label={t('label.secondary_user_permission')}
							/>
							<DividerComponent />
						</>
					) : (
						(representatives?.length > 0 || accountsWithNoRepresentatives?.length > 0 || isHBLbrand) && (
							<>
								<LinkItemComponent
									id="nominated-representative"
									route="/settings/nominated-representative"
									icon="icon-briefcase"
									label={t('label.nominated_representative')}
								/>
								<DividerComponent />
							</>
						)
					)}
					<div className="settings-page__bottom-wrapper">
						<div className="settings-page__open-banking-wrapper">
							<div className="settings-page__open-banking-header">
								<span className="icon-question-mark-circle"></span>
								<label>{t('label.open_banking_faqs')}</label>
							</div>
							<div className="settings-page__open-banking-link-wrapper">
								<label className="settings-page__open-banking-footer">People First Bank:</label>
								<a target="_blank" href={globalConstants.PC_OPEN_BANKING_LINK} rel="noopener noreferrer">
									{globalConstants.PC_OPEN_BANKING_LINK}
								</a>
								<label className="settings-page__open-banking-footer settings-page__heritage-bank">Heritage Bank:</label>
								<a target="_blank" href={globalConstants.HERITAGE_OPEN_BANKING_LINK} rel="noopener noreferrer">
									{globalConstants.HERITAGE_OPEN_BANKING_LINK}
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default withTranslation()(SettingsContentPage);
